import { Typography, Button, TextField } from '@material-ui/core'
import MaterialUiPhoneNumber from 'material-ui-phone-number'
import React, { useEffect } from 'react'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import { useHistory } from 'react-router-dom'
import './home.css'
import { IconButton } from '@material-ui/core';
function Home() {
    const history = useHistory()
    const location = history.location
    const [config, setconfig] = React.useState({
        phone: '',
        message: '',
        showmsg: false,
        clipboard: true
    })
    const sendmsg = e => {
        e.preventDefault()
        navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;
        if (navigator.vibrate) {
            navigator.vibrate(60);
        }
        history.push(`/send?phone=${config.phone}&text=${config.message}`)
    }
    const pasteMessage = () => {
        if (navigator.clipboard) {
            navigator.clipboard.readText()
                .then(text => {
                    setconfig({ ...config, message: text })
                })
                .catch(err => {
                    alert('Please allow to use clipboard for easy access')
                });
        }
        else {
            setconfig({ ...config, clipboard: false })
        }

    }
    const pasteNum = () => {
        if (navigator.clipboard) {
            navigator.clipboard.readText()
                .then(text => {
                    setconfig({ ...config, phone: text.trim() })
                    // `text` contains the text read from the clipboard
                })
                .catch(err => {
                    alert('Please allow to use clipboard for easy access')
                });
        }
        else {
            setconfig({ ...config, clipboard: false })
        }

    }
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        params.get('edit') === 'true' && setconfig(pv => ({
            ...pv,
            phone: `+${params.get('phone').trim()}`,
            showmsg: params?.get('text')?.length > 0 ? true : false,
            message: params.get('text')
        }))
    }, [location])
    return (
        <>
            <Typography variant="h5" color="initial">
                Send Whatsapp messsages to anyone without saving number
            </Typography>
            <br />
            <br />
            <form style={{ width: '100%' }} onSubmit={sendmsg}>
                <MaterialUiPhoneNumber
                    variant={'outlined'}
                    autoFocus={true}
                    required
                    autoComplete='true'
                    fullWidth={true}
                    // margin={'dense'}
                    InputProps={{
                        endAdornment: config.clipboard && (<IconButton variant="outlined" aria-label="Paste Number" onClick={pasteNum}>
                            <AssignmentOutlinedIcon />
                        </IconButton>
                        )
                    }}
                    // InputAdornment={{start:<>sdf</>}}
                    placeholder="Phone Number"
                    value={`${config.phone || ''}`}
                    onChange={(e, d) => setconfig(pv => ({ ...pv, phone: e }))}
                    //phoneinput variables
                    preferredCountries={['in']}
                    defaultCountry={'in'}
                    countryCodeEditable={false}
                    disableDropdown={false}
                    enableLongNumbers={true}
                    disableAreaCodes={true}
                    isValid={(value, country) => {
                        if (value.match(/12345/)) {
                            return 'Invalid value: ' + value + ', ' + country.name;
                        } else if (value.match(/1234/)) {
                            return false;
                        } else {
                            return true;
                        }
                    }}
                    autoFormat={true}

                />
                {config.showmsg &&
                    <>
                        <br /><br />
                        <TextField
                            multiline={true}
                            rows={3}
                            id=""
                            label="Message"
                            placeholder="Your custom message"
                            fullWidth={true}
                            InputProps={{
                                endAdornment: config.clipboard && (<IconButton variant="outlined" aria-label="Paste Message" onClick={pasteMessage}>
                                    <AssignmentOutlinedIcon />
                                </IconButton>
                                )
                            }}
                            variant="outlined"
                            value={`${config.message || ''}`}
                            onChange={e => setconfig({ ...config, message: e.target.value })}
                        />
                    </>

                }
                <br />
                <br />

                <Button size="small" variant="outlined" aria-label="Paste Number" onClick={() => setconfig({ ...config, showmsg: !config.showmsg })}>
                    <AssignmentOutlinedIcon fontSize="small" /> &nbsp; {config.showmsg ? 'Hide' : 'Add'} Message
                </Button>
                <Button size="large" type="submit" className={`lrg_btn`} fullWidth disableElevation variant="contained" color="primary">
                    Message
                </Button>
            </form>

        </>
    )
}

export default Home
